.cursor,
.cursorPosition { pointer-events: none; }
.cursor { transform: translate(-50%, -50%); }

.componentImpl {
  cursor: none;
  position: relative;

  & > .cursorPositionLayout {
    position: absolute;
    left: 50%;
    top: 50%;
    display: none;

    @media (--viewport-sm) {
      display: block;
    }
  }
}

.componentCursorPositionImpl {
  will-change: transform;
}
